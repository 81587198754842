// import './App.css';
// import ChatPage from './ChatPage';
// import LandingPage from './LandingPage';
// import ChatPageOakNorth from './ChatPageOakNorth';
// import PasswordPage from './PasswordPage';
// import React, { useState, useEffect } from 'react';
// import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
// import crypto from 'crypto-js';

// function App() {
//   const [isAuthenticated, setIsAuthenticated] = useState(false);
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     const authToken = localStorage.getItem('authToken');
//     const correctHash = crypto.SHA256('your_password_here').toString(); // replace 'your_password_here' with the actual password

//     if (authToken === correctHash) {
//       setIsAuthenticated(true);
//     }
//     setLoading(false); // Set loading to false after checking authentication
//   }, []);

//   useEffect(() => {
//     console.log('isAuthenticated changed:', isAuthenticated);
//   }, [isAuthenticated]);

//   if (loading) {
//     return <div>Loading...</div>; // Show a loading message while checking authentication
//   }

//   return (
//     <Router>
//       <Routes>
//         <Route path="/" element={<LandingPage />} />
//         <Route path="/chat" element={<ChatPage />} />
//         <Route path="/oaknorth" element={isAuthenticated ? <ChatPageOakNorth /> : <Navigate to="/password" />} />
//         <Route path="/password" element={<PasswordPage setAuthenticated={setIsAuthenticated} />} />
//       </Routes>
//     </Router>
//   );
// }

// export default App;

import './App.css';
import ChatPage from './ChatPage';
import LandingPage from './LandingPage';
import ChatPageOakNorth from './ChatPageOakNorth';
import PrivacyPolicy from './PrivacyPolicyPage';
import PasswordPage from './PasswordPage';
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import crypto from 'crypto-js';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const authToken = localStorage.getItem('authToken');
    const correctHash = crypto.SHA256('thisisjuno2024').toString(); // replace 'your_password_here' with the actual password

    if (authToken === correctHash) {
      setIsAuthenticated(true);
    }
    setLoading(false); // Set loading to false after checking authentication
  }, []);

  useEffect(() => {
    console.log('isAuthenticated changed:', isAuthenticated);
  }, [isAuthenticated]);

  if (loading) {
    return <div>Loading...</div>; // Show a loading message while checking authentication
  }

  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/chat" element={<ChatPage />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route
          path="/oaknorth"
          element={<ProtectedRoute isAuthenticated={isAuthenticated} />}
        />
        <Route path="/password" element={<PasswordPage setAuthenticated={setIsAuthenticated} />} />
      </Routes>
    </Router>
  );
}

function ProtectedRoute({ isAuthenticated }) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const redirectTo = `/password?${queryParams.toString()}`;

  if (!isAuthenticated) {
    return <Navigate to={redirectTo} />;
  }

  return <ChatPageOakNorth />;
}

export default App;
