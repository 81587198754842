import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import Navbar from './Navbar';
import "./LandingPage.css";
import ThreeDAnimation from './ThreeDAnimation';
// import MailchimpForm from './MailChimp';
// import CookieConsent from "react-cookie-consent";


function LandingPage() {

  return (
    <div className="absolute inset-0 h-full w-full bg-[#fdfcf3] bg-[linear-gradient(to_right,#80808012_1px,transparent_1px),linear-gradient(to_bottom,#80808012_1px,transparent_1px)] bg-[size:120px_120px] text-blue-900">
        <Navbar />
        
        <main className="flex-1">
        <section className="w-full py-12 px-4 md:py-24 lg:py-32 xl:py-48">
        <ThreeDAnimation />
              <div className="text-center mt-4 md:mt-0 flex-grow flex items-center justify-center">
                  <div className="space-y-5 bg-[#fdfcf3] bg-[linear-gradient(to_right,#80808012_1px,transparent_1px),linear-gradient(to_bottom,#80808012_1px,transparent_1px)] bg-[size:120px_120px]">
                      <h1 className="text-7xl font-bold tracking-tighter mb-4">Generative <a className="text-[#AF1E88]">AI-Powered OS</a> for Financial Services</h1>
                      <p className="mx-auto max-w-[700px] text-gray-900 font-bold md:text-xl">
                          Save <b>85%</b> of operational costs for commercial brokers and lenders.
                      </p>
                      <div className="space-x-4 relative"> 
                      <button
                        // onClick={() => handleLogin('/chat')}
                        onClick={() => window.open('https://calendly.com/jordan-4zbw/30min', '_blank')}
                        // href="https://calendly.com/jordan-4zbw/30min"
                        className="glow-button text-xl bg-[#AF1E88] hover:bg-blue-700 text-white font-bold py-4 px-8 rounded mt-2"
                        // className="bg-[#af8d1e] hover:bg-gradient-to-l hover:from-pink-500 hover:via-red-500 hover:to-yellow-400 text-white font-extrabold py-4 px-8 rounded-full mt-2 shadow-lg transform hover:scale-110 transition-transform duration-300"
                        // style={{
                        //       fontSize: '1.5rem',
                        //       border: '3px solid #1e40af',
                        //       textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
                        // }}
                      >
                        Book a demo &#8594;
                        {/* <svg
                          width="19"
                          height="14"
                          viewBox="0 0 19 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1.75 7L16.4167 7M11.8333 12.5L16.6852 7.64818C16.9907 7.34263 17.1435 7.18985 17.1435 7C17.1435 6.81015 16.9907 6.65737 16.6852 6.35182L11.8333 1.5"
                            stroke="#4F46E5"
                            stroke-width="2.4"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg> */}
                      </button>
                        {/* <MailchimpForm /> */}
                        {/* <img src="juno_screenshot_1.png"></img> */}
                          {/* <div className="relative inline-block"> 
                              <Link
                                  to="/chat"
                                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2"
                                  >
                                  Chat to Juno
                              </Link>
                              <span className="absolute top-0 right-4 transform -translate-y-5 translate-x-1/2 bg-red-600 text-white py-1 px-2 text-xs rounded">BETA</span>
 
                          </div> */}
                          {/* <Link
                              to="/#about"
                              className="bg-transparent hover:bg-blue-500 text-blue-500 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
                              >
                              Learn More
                          </Link> */}
                      </div>
                  </div>
              </div>
          </section>
          
            <section className="flex items-center justify-center w-full bg-blue-800 z-1 relative" id="about">
                <div className="container px-4 md:px-6">
                    {/* <div className="grid items-center gap-4 lg:grid-cols-2 lg:gap-6"> */}
                        {/* <img
                            alt="placeholder"
                            className="mx-auto lg:aspect-video overflow-hidden rounded-xl object-cover object-center sm:w-full lg:order-last"
                            // height="310"
                            src="./juno_screenshot_1.png"
                            // width="550"
                        /> */}
                        <div className="justify-center space-y-4 py-6">
                            {/* <div className="space-y-2 ">
                                <h2 className="text-white text-3xl font-bold tracking-tighter sm:text-5xl">
                                    Supercharge your revenue <a className="text-[#cf78b8]">with an AI Employee</a> 
                                </h2>
                                <p className="max-w-[600px] text-white md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed">
                                    Juno <b>eliminates the need for manual processing</b>, saving up to 2% of the loan amount.
                                </p>
                            </div>   */}
                            <div className="space-y-2 text-center mx-auto">
                                <h2 className="text-white text-3xl font-bold tracking-tighter sm:text-5xl">
                                    Supercharge your revenue <a className="text-[#cf78b8]">with an AI Employee</a> 
                                </h2>
                                <p className="text-white md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed">
                                    Juno <b>eliminates the need for manual processing</b>, saving up to 2% of the loan amount.
                                </p>
                            </div>

                            <div className="flex flex-col sm:flex-row justify-center sm:space-x-36 space-y-4 sm:space-y-0 pt-4">
                                <div className="flex items-center space-x-4">
                                    {/* <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 text-white" viewBox="0 0 20 20" fill="currentColor">
                                        <path d="M10 2a5 5 0 015 5v3.586l.707.707a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414L5 10.586V7a5 5 0 015-5zm-3 8.586L4.586 10 10 4.586 15.414 10 13 12.414V7a3 3 0 00-6 0v3.586z" />
                                    </svg> */}
                                    <img src="/admin.svg" alt="Admin Icon" className="h-12 w-12 filter invert" />
                                    <p className="text-white"><b>Admin</b></p>
                                </div>
                                <div className="flex items-center space-x-4">
                                    {/* <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 text-white" viewBox="0 0 20 20" fill="currentColor">
                                        <path d="M10 2a5 5 0 015 5v3.586l.707.707a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414L5 10.586V7a5 5 0 015-5zm-3 8.586L4.586 10 10 4.586 15.414 10 13 12.414V7a3 3 0 00-6 0v3.586z" />
                                    </svg> */}
                                    <img src="/sales_2.svg" alt="Sales Icon" className="h-12 w-12 filter invert" />
                                    <p className="text-white"><b>Sales</b></p>
                                </div>
                                <div className="flex items-center space-x-4">
                                    {/* <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 text-white" viewBox="0 0 20 20" fill="currentColor">
                                        <path d="M10 2a5 5 0 015 5v3.586l.707.707a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414L5 10.586V7a5 5 0 015-5zm-3 8.586L4.586 10 10 4.586 15.414 10 13 12.414V7a3 3 0 00-6 0v3.586z" />
                                    </svg> */}
                                    <img src="/onboarding_2.svg" alt="Onboarding Icon" className="h-12 w-12 filter invert" />
                                    <p className="text-white"><b>Onboarding</b></p>
                                </div>
                            </div>
                        </div>

                        
                        {/* <div className="justify-center space-y-4 py-6"> */}
                            {/* <div className="space-y-2"> */}
                                {/* <h2 className="text-white text-3xl font-bold tracking-tighter sm:text-5xl">Supercharge your revenue <br/><a className="text-[#cf78b8]">with an AI Employee</a> </h2>
                                <p className="max-w-[600px] text-white md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed">
                                  Juno <b>eliminates the need for manual processing</b>, saving up to 2% of the loan amount.
                                </p> */}
                                {/* <p className="max-w-[600px] text-gray-200 md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed">
                                  Internal processes become automatic, saving cost of hiring 3 staff members.
                                </p>
                                <p className="max-w-[600px] text-gray-200 md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed">
                                Reduce processing times for applications by 90%.
                                </p>
                                <p className="max-w-[600px] text-gray-200 md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed">
                                Reduce processing times for applications by 90%.
                                </p> */}

                                

                            {/* </div> */}
                            {/* <div className="justify-center space-y-4 py-6">
                              <div className="space-y-2">
                                  <h2 className="text-white text-3xl font-bold tracking-tighter sm:text-5xl">
                                      Supercharge your revenue <br/><a className="text-[#cf78b8]">with an AI Employee</a> 
                                  </h2>
                                  <p className="max-w-[600px] text-white md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed">
                                      Juno <b>eliminates the need for manual processing</b>, saving up to 2% of the loan amount.
                                  </p>
                              </div>  
                              <div className="flex flex-col sm:flex-row justify-center sm:space-x-8 pt-4 space-y-4 sm:space-y-0">
                                  <div className="flex flex-col sm:flex-row items-center sm:space-x-4">
                                      <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 text-white" viewBox="0 0 20 20" fill="currentColor">
                                          <path d="M10 2a5 5 0 015 5v3.586l.707.707a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414L5 10.586V7a5 5 0 015-5zm-3 8.586L4.586 10 10 4.586 15.414 10 13 12.414V7a3 3 0 00-6 0v3.586z" />
                                      </svg>
                                      <p className="text-white sm:mt-0 mt-2">Admin</p>
                                  </div>
                                  <div className="flex flex-col sm:flex-row items-center sm:space-x-4">
                                      <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 text-white" viewBox="0 0 20 20" fill="currentColor">
                                          <path d="M10 2a5 5 0 015 5v3.586l.707.707a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414L5 10.586V7a5 5 0 015-5zm-3 8.586L4.586 10 10 4.586 15.414 10 13 12.414V7a3 3 0 00-6 0v3.586z" />
                                      </svg>
                                      <p className="text-white sm:mt-0 mt-2">Sales</p>
                                  </div>
                                  <div className="flex flex-col sm:flex-row items-center sm:space-x-4">
                                      <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 text-white" viewBox="0 0 20 20" fill="currentColor">
                                          <path d="M10 2a5 5 0 015 5v3.586l.707.707a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414L5 10.586V7a5 5 0 015-5zm-3 8.586L4.586 10 10 4.586 15.414 10 13 12.414V7a3 3 0 00-6 0v3.586z" />
                                      </svg>
                                      <p className="text-white sm:mt-0 mt-2">Onboarding</p>
                                  </div>
                              </div>
                          </div>

                           
                        </div> */}
                    {/* </div> */}
                </div>
            </section>

        {/* <section className="flex items-center justify-center w-full py-12">
            <div className="container px-6 md:px-6">
                <div className="grid items-center gap-4 px-6 lg:grid-cols-2 lg:gap-6">
                    <div className="space-y-4">
                    <img
                            alt="placeholder"
                            className="mx-auto aspect-video overflow-hidden rounded-xl object-scale-down object-center sm:w-full lg:order-first"
                            src="./ai_logo.png"
                        />
                         
                    </div>
                    
                    <div className="justify-center space-y-4">
                        <div className="space-y-6">
                            <h2 className="text-blue-900 text-3xl font-bold tracking-tighter sm:text-5xl">How It Works</h2>

                            <p className="max-w-[600px] text-blue-900 md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed">
                            Juno assesses your needs via natural conversation, and analyses your business profile to match you with the best loan options. 
                            </p>
                            <p className="max-w-[600px] text-blue-900 md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed">
                            Through a combination of generative AI and financial expertise, Juno sources deals, compares products, guides applications 
                            and offers post-loan support.
                            </p>
                            <img
                                alt="placeholder"
                                className="mx-auto aspect-video overflow-hidden rounded-xl object-scale-down object-center sm:w-full lg:order-first"
                                src="./juno_process.png"
                            />
                        </div>
                        
                    </div>
                </div>
            </div>
        </section> */}
           
        {/* <section className="px-16 py-6 lg:px-26 lg:py-12 border-b border-t border-blue-700">
          <div className="grid gap-12 lg:grid-cols-3">
            <div className="px-6 py-6 rounded-lg border border-grey-500 shadow-md">
              <h2 className="text-2xl font-bold text-blue-900 dark:text-gray-100">Our Mission</h2>
              <p className="mt-4 text-gray-800 dark:text-gray-400">
              At Juno, our mission is to leverage advanced AI technology to provide tailored loan options
              for small businesses. We aim to simplify financial processes, making them more accessible and
              efficient for entrepreneurs and business owners.
              </p>
            </div>
            <div className="px-6 py-6 rounded-lg border border-grey-500 shadow-md">
              <h2 className="text-2xl font-bold text-blue-900 dark:text-gray-100">What We Do</h2>
              <p className="mt-4 text-gray-800 dark:text-gray-400">
              Using a combination of machine learning algorithms and financial expertise, Juno analyzes
              various loan options to find the best match for your business's unique needs. We streamline
              the application process to save you time and effort, ensuring you get the support you need
              to grow your business.
              </p>
            </div>
            <div className="px-6 py-6 rounded-lg border border-grey-500 shadow-md">
              <h2 className="text-2xl font-bold text-blue-900 dark:text-gray-100">Why Us</h2>
              <p className="mt-4 text-gray-800 dark:text-gray-400">
              Juno's broker will significantly reduce the cost, time and complexity of securing business 
              finance for SMEs, reducing costs by 5-15% annually, enhancing the terms offered and expediting 
              application-to-offer time by 5-10 days. 
              </p>
            </div>
          </div>
        </section> */}

        <section className="px-4 py-12 lg:px-6 lg:py-24 bg-[#fdfcf3] z-1 relative" id="team">
          <div className="max-w-7xl mx-auto space-y-12">
            <h2 className="text-blue-900 text-3xl font-bold tracking-tighter sm:text-5xl">Meet Our Team</h2>
            <div className="grid gap-12 lg:grid-cols-3">
              <div className="space-y-4">
                <img
                  alt="Team member 1"
                  className="w-full h-64 object-cover rounded-lg"
                  height="300"
                  src="/archit.jpg"
                  style={{
                    aspectRatio: "300/300",
                    objectFit: "cover",
                  }}
                  width="300"
                />
                <h3 className="text-xl font-bold">Archit Tiwari</h3>
                <p className="font-bold text-gray-600 dark:text-gray-400">Co-founder & CEO</p>
                <p className="text-gray-600">
                  MBA, Insead. Bootstrapped SME from 0 to £5mn+. Managed multiple $100mn+ revenue businesses.
                  Senior Product Manager, Amazon Special Projects.
                </p>
              </div>
              <div className="space-y-4">
                <img
                  alt="Team member 2"
                  className="w-full h-64 object-cover rounded-lg"
                  height="300"
                  src="/max.jpeg"
                  style={{
                    aspectRatio: "300/300",
                    objectFit: "cover",
                  }}
                  width="300"
                />
                <h3 className="text-xl font-bold">Max Chmyshuk</h3>
                <p className="font-bold text-gray-600 dark:text-gray-400">Co-founder & CEO</p>
                <p className="text-gray-600">
                  Co-founded Fleximize, a £250mn+ growth finance fintech. Founded Angular capital. 
                  Ex Director, Investment Banking, Citi, Dresdner Kleinwort.
                </p>
              </div>
              <div className="space-y-4">
                <img
                  alt="Team member 3"
                  className="w-full h-64 object-cover rounded-lg"
                  height="300"
                  src="/jordan.jpg"
                  style={{
                    aspectRatio: "300/300",
                    objectFit: "cover",
                  }}
                  width="300"
                />
                <h3 className="text-xl font-bold">Jordan Smith</h3>
                <p className="font-bold text-gray-600 dark:text-gray-400">Head of Product & Technical Lead</p>
                <p className="text-gray-600">
                  Product Lead at Behold.ai, scaled diagnostic AI across NHS. 
                  Responsible for cryptographic information assurance for UK national defence. 
                </p>
              </div>
              <div className="space-y-4">
                <img
                  alt="Team member 3"
                  className="w-full h-64 object-cover rounded-lg"
                  height="300"
                  src="/nikita.png"
                  style={{
                    aspectRatio: "300/300",
                    objectFit: "cover",
                  }}
                  width="300"
                />
                <h3 className="text-xl font-bold">Nikita Severin</h3>
                <p className="font-bold text-gray-600 dark:text-gray-400">Chief Data Scientist</p>
                <p className="text-gray-600">
                PhD in Machine learning, specialized in generative AI and natural language processing. 
                Built intelligent text processing at Trading View. 

                </p>
              </div>
              <div className="space-y-4">
                <img
                  alt="Team member 3"
                  className="w-full h-64 object-cover rounded-lg img-top-cropped"
                  height="300"
                  src="/aleksei.png"
                  style={{
                    aspectRatio: "300/300",
                    objectFit: "cover",
                  }}
                  width="300"
                />
                <h3 className="text-xl font-bold">Aleksei Artiushenko</h3>
                <p className="font-bold text-gray-600 dark:text-gray-400">Software Engineer</p>
                <p className="text-gray-600">
                Developer with a focus on web technologies, with experience in early end-to-end development. 
                Specialises in creating innovative solutions to improve user experiences. 
                </p>
              </div>
            </div>
            
          </div>
        </section>

        {/* <section className="bg-[#fdfcf3] py-8" id="waitlist">
            <MailchimpForm />
        </section> */}
          
        
        </main>
        <footer className="flex flex-col gap-2 sm:flex-row py-6 w-full shrink-0 items-center px-4 md:px-6 border-t border-blue-700">
            <p className="text-xs">© Juno. All rights reserved.</p>
            <nav className="sm:ml-auto flex gap-4 sm:gap-6">
                <Link className="text-xs hover:underline underline-offset-4" to="https://www.termsfeed.com/live/868ca480-60e5-4091-a7dd-3856efc2c37d">
                Terms of Service
                </Link>
                {/* <Link className="text-xs hover:underline underline-offset-4" to="https://www.termsfeed.com/live/4f3ebefe-fb02-4b06-818c-66a22151d721"> */}
                <Link className="text-xs hover:underline underline-offset-4" to="/privacypolicy">
                Privacy Policy
                </Link>
            </nav>
        </footer>
        {/* <CookieConsent
            location="bottom"
            buttonText="Okay"
            style={{ background: "white", color: "black" }}
            buttonStyle={{ background: "blue", color: "white", fontSize: "13px" }}
            expires={150}
            >
            This website uses cookies to enhance the user experience.{" "}
        </CookieConsent> */}
        
    </div>
  );
}

export default LandingPage;

