import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
// import { useNavigate } from 'react-router-dom';
import NavbarOakNorth from './NavbarOakNorth';
import CookieConsent from "react-cookie-consent";
import './ChatPage.css';
import { useEffect, useState } from 'react';
import { postUserIdentifier } from './utilsOakNorth';
import { sendUserDetails } from './utilsOakNorth';
import { sendPhoneRequest } from './utilsOakNorth';
import { postUserIdentifierBeacon } from './utilsOakNorth';
import { getConversationByUserIdentifier } from './utilsOakNorth';
import { debounce } from 'lodash';
import { FaBars } from 'react-icons/fa'; // Importing an icon from react-icons
// import { Collapse } from 'react-collapse'; // Importing Collapse from react-collapse


function ChatPageOakNorth() {

    const [userIdentifier, setUserIdentifier] = useState('');
    const [showForm, setShowForm] = useState(false);
    const [tosAccepted, setTosAccepted] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [emailAddress, setEmail] = useState('');
    const [companyName, setCompany] = useState('');
    const [phoneNumber, setPhone] = useState('');
    const [showThankYouMessage, setShowThankYouMessage] = useState(false);
    const [companySuggestions, setCompanySuggestions] = useState([]);
    const [companyNumber, setCompanyNumber] = useState('');

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
        console.log(isMenuOpen);
    };

    


    useEffect(() => {

        console.log('test env:', process.env.TEST_ENV);

        // Function to retrieve userParams from localStorage and send them
        // const loadAndSendUserDetails = async () => {
        //     // Retrieve userParams from localStorage
        //     const userParamsJson = localStorage.getItem('userParams');
            
        //     if (userParamsJson) {
        //     const userParams = JSON.parse(userParamsJson);
            
        //     // Send userParams using the sendUserDetails function
        //     try {
        //         const insertedId = await sendUserDetails(userParams);
        //         console.log('Inserted ID:', insertedId);
        //         // Further actions based on response, if needed

        //         let storedUserIdentifier = localStorage.getItem('userIdentifier');
        //         if (!storedUserIdentifier) {
        //             storedUserIdentifier = insertedId;
        //             localStorage.setItem('userIdentifier', storedUserIdentifier);
        //         }
        //         setUserIdentifier(storedUserIdentifier);
        //     } catch (error) {
        //         console.error("Error sending user details:", error);
        //     }
        //     }
        // };
        // loadAndSendUserDetails();

        const manageUserIdentiferFromURL = async () => {
            const queryParams = new URLSearchParams(window.location.search);
            const userIdentiferFromURL = queryParams.get('userIdentifier');

            if (userIdentiferFromURL && userIdentiferFromURL.length > 0) {
                try {
                    const response = await getConversationByUserIdentifier(userIdentiferFromURL);
                    if (response && response.ok) {
                        localStorage.setItem('userIdentifier', userIdentiferFromURL);

                        const conversationDetailsObj = await response.json()
                        const messages = conversationDetailsObj.messages;
                        if (messages) {
                            const iframe = document.getElementById('super_embed');
                
                            var targetOrigin = "https://www.superdashhq.com";
                                                
                            iframe.contentWindow.postMessage({
                                action: "updateConversations",
                                conversations: messages
                            }, targetOrigin);
                
                            console.log('Posted messages:', messages);
                
                          }
                    } else {
                        console.error('Failed to retrieve conversation for user');
                    }
                } catch (error) {
                    console.error('Error in retrieving conversation:', error);
                }
            }
        };


        // Function to retrieve userParams from localStorage and send them
        const loadAndSendUserDetails = async () => {
            await manageUserIdentiferFromURL();
            // Retrieve userParams from localStorage
            const userParamsJson = localStorage.getItem('userParams');
            
            if (userParamsJson) {
                const userParams = JSON.parse(userParamsJson);
                // console.log("userParams: ", userParams);
            
                // Send userParams using the sendUserDetails function
                try {
                    const insertedId = await sendUserDetails(userParams);
                    console.log('Inserted ID:', insertedId);
                    // Further actions based on response, if needed

                    
                    let storedUserIdentifier = localStorage.getItem('userIdentifier');
                    if (!storedUserIdentifier) {
                        storedUserIdentifier = insertedId;
                        localStorage.setItem('userIdentifier', storedUserIdentifier);
                    }
                    setUserIdentifier(storedUserIdentifier);
                } catch (error) {
                    console.error("Error sending user details:", error);
                }
            } else {
                // get userParams from url instead and sendUserDetails()
                const urlParams = new URLSearchParams(window.location.search);
                const userParams = {
                    companyName: urlParams.get('companyName'),
                    emailAddress: urlParams.get('emailAddress'),
                    firstName: urlParams.get('firstName'),
                    lastName: urlParams.get('lastName'),
                    phoneNumber: urlParams.get('phoneNumber'),
                    companyNumber: urlParams.get('companyNumber')
                };

                // Send userParams using the sendUserDetails function
                try {
                    const insertedId = await sendUserDetails(userParams);
                    console.log('Inserted ID:', insertedId);
                    } catch (error) {
                        console.error("Error sending user details from URL:", error);
                    }
                }
        };
        loadAndSendUserDetails();

        // const requiredParams = ['firstName', 'lastName', 'emailAddress', 'companyName', 'phoneNumber'];
        // const queryParams = new URLSearchParams(window.location.search);
        // setFirstName(queryParams.get('firstName') || '');
        // setLastName(queryParams.get('lastName') || '');
        // setEmail(queryParams.get('emailAddress') || '');
        // setCompany(queryParams.get('companyName') || '');
        // setPhone(queryParams.get('phoneNumber') || '');
        // setCompanyNumber(queryParams.get('companyNumber') || '');
        // const userParams = JSON.parse(localStorage.getItem('userParams'));

        // // const isParamMissingInURL = requiredParams.some(param => !queryParams.has(param));
        // const isParamMissingInLocalStorage = requiredParams.some(param => !(userParams && userParams[param]));
    
        // setShowForm(isParamMissingInLocalStorage);

        // Define the required parameters
        const requiredParams = ['firstName', 'lastName', 'emailAddress', 'companyName', 'phoneNumber', 'companyNumber'];

        // Read the URL query parameters
        const queryParams = new URLSearchParams(window.location.search);
        const paramsObject = {};

        // Loop through required parameters to populate the paramsObject
        requiredParams.forEach(param => {
            const value = queryParams.get(param) || '';
            paramsObject[param] = value;
        });

        // Store the parameters into localStorage only if all are provided
        const isParamMissingInURL = requiredParams.some(param => !queryParams.has(param));
        if (!isParamMissingInURL) {
            localStorage.setItem('userParams', JSON.stringify(paramsObject));
        }

        // Retrieve userParams from localStorage for further checks
        const userParams = JSON.parse(localStorage.getItem('userParams'));

        // Check if any required parameter is missing in localStorage
        const isParamMissingInLocalStorage = requiredParams.some(param => !(userParams && userParams[param]));

        // Set the state to show or hide the form
        setShowForm(isParamMissingInLocalStorage);

        // Update the component state using the URL parameters or default empty values
        setFirstName(paramsObject['firstName']);
        setLastName(paramsObject['lastName']);
        setEmail(paramsObject['emailAddress']);
        setCompany(paramsObject['companyName']);
        setPhone(paramsObject['phoneNumber']);
        setCompanyNumber(paramsObject['companyNumber']);

        const tosAccepted = sessionStorage.getItem('tosAccepted') === 'true';
        setTosAccepted(tosAccepted);

        const handleBeforeUnload = (event) => {
            const userIdentifier = localStorage.getItem('userIdentifier'); // Assuming userIdentifier is stored in localStorage
            if (userIdentifier) {
            // postUserIdentifier(userIdentifier);
                userParams.userIdentifier = userIdentifier;
                postUserIdentifierBeacon(userParams);
              console.log('posted')
              // Depending on browser support and requirements, you might not see console logs for async operations in beforeunload
            }
          };
        
          window.addEventListener('beforeunload', handleBeforeUnload);
        
          return () => {
             window.removeEventListener('beforeunload', handleBeforeUnload);
          };

          
    }, []);

    console.log('userIdentifier:', userIdentifier);

    const userParams = JSON.parse(localStorage.getItem('userParams') || '{}');
    const iframeSrcBaseUrl = "https://www.superdashhq.com/embed?projectID=" + process.env.REACT_APP_OAKNORTH_SUPERDASH_PROJECT_ID;
    const iframeParams = new URLSearchParams({
        ...userParams,
        userIdentifier: userIdentifier,
    }).toString();
    const iframeSrcUrl = `${iframeSrcBaseUrl}&${iframeParams}`;
    // console.log(iframeSrcUrl);

    const acceptTerms = () => {
        sessionStorage.setItem('tosAccepted', 'true');
        setTosAccepted(true);
    };

    // const handleGetMyLoanClick = () => {
    //     postUserIdentifier(userIdentifier)
    //     // postUserIdentifierBeacon(userIdentifier)
    //     // console.log('posted');
    //       .then(() => {
    //         console.log('User identifier posted successfully');
    //         setShowThankYouMessage(true);
    //       }) 
    //       .catch((error) => console.error('Failed to post user identifier', error));
    //   };

    const handlePhoneRequestClick = () => {
        // setShowThankYouMessage(true);
        postUserIdentifier(userIdentifier)
            .then(response => {
                setShowThankYouMessage(true);
                // console.log('show thank you', showThankYouMessage);
                // Assuming postUserIdentifier is adjusted to resolve with the response object
                if (response.ok) {
                    console.log('User identifier posted successfully');
                    console.log('show thank you', showThankYouMessage);
                } else if (response.status === 400) {
                    // Handle 400 response specifically
                    console.log('bad request:', response)
                    // handleBadRequest(); // This is a function you would define to handle a 400 Bad Request response.
                }
            }) 
            .catch((error) => {
                console.error('Failed to post user identifier on phoneRequest', error);
                // Additional error handling here if needed
                userParams.userIdentifier = userIdentifier;
                sendPhoneRequest(userParams);
                setShowThankYouMessage(true);
            });
            
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Gather form values
        const firstName = e.target.elements['first-name'].value;
        const lastName = e.target.elements['last-name'].value;
        const emailAddress = e.target.elements['emailAddress'].value;
        const companyName = e.target.elements['companyName'].value;
        const phoneNumber = e.target.elements['phoneNumber'].value;
        const emailUpdates = e.target.elements['email-updates'].checked.toString();
        const termsOfService = e.target.elements['terms-of-service'].checked.toString();
        const companyNumber = e.target.elements['companyNumber'] ? e.target.elements['companyNumber'].value : '';

        // Construct the data object conditionally
        let data = {
            firstName,
            lastName,
            emailAddress,
            companyName,
            phoneNumber,
            emailUpdates,
            termsOfService, // Uncomment if needed
        };

        // Include companyNumber only if it exists
        if (companyNumber) {
            data.companyNumber = companyNumber;
        }

        // Construct URL with query parameters from data object
        const queryParams = new URLSearchParams(data).toString();

        // Save to localStorage
        localStorage.setItem('userParams', JSON.stringify(data));

        sessionStorage.setItem('tosAccepted', termsOfService);
        window.location.href = `${window.location.pathname}?${queryParams}`;
    };

    // Fetch company suggestions
    const fetchCompanySuggestions = debounce(async (query) => {
        if (!query) {
            setCompanySuggestions([]);
            return;
        }

        try {
            const response = await fetch(`/api/proxy?query=${encodeURIComponent(query)}`);
            if (!response.ok) throw new Error('Failed to fetch');

            // console.log('response', await response.text());
            const suggestions = await response.json();

            // console.log('suggestions:', suggestions);
            
            setCompanySuggestions(suggestions.items);
        } catch (error) {
            console.error("Error fetching company suggestions:", error);
        }
    }, 300); // Adjust debounce timing as needed

    const startAgain = () => {
        userParams.userIdentifier = userIdentifier;
        postUserIdentifierBeacon(userParams);

        // Clear local storage
        // localStorage.clear();
        localStorage.removeItem('userParams');
        localStorage.removeItem('userIdentifier');
        console.log('Console cleared');
        
        // Add clearLocalStorage=true parameter to iframeSrcUrl
        const updatedIframeSrcUrl = `${iframeSrcUrl}&clearLocalStorage=true`;
        
        const iframe = document.getElementById('super_embed');
    
        // Define a function to handle page reload
        const reloadPage = () => {
            const currentUrl = window.location.href;
            // Reload the page with the updated URL
            window.location.href = currentUrl.toString();
        };
    
        // Set onload event listener for the iframe
        iframe.onload = () => {
            // Call reloadPage function after iframe finishes loading
            reloadPage();
        };
    
        // Reload the iframe content with the updated URL
        iframe.src = updatedIframeSrcUrl;
    };

    // async function fetchConversation(userIdentifier) {
    //     try {
    //       const conversationDetails = await getConversationByUserIdentifier(userIdentifier);
      
    //       if (conversationDetails) {
    //         console.log('Conversation Details:', conversationDetails);
    //         const iframe = document.getElementById('super_embed');

    //         var targetOrigin = "https://www.superdashhq.com";
                                
    //         iframe.contentWindow.postMessage({
    //             action: "updateConversations",
    //             conversations: conversationDetails
    //         }, targetOrigin);

    //       } else {
    //         console.log('No conversation details found or error occurred');
    //       }
    //     } catch (error) {
    //       console.error('Failed to fetch conversation:', error);
    //     }
    //   }

      // Call the function
    // fetchConversation('66154a0c3c1b641d7ce6b2d9');

    // const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    // const toggleDropdown = () => {
    //     setIsDropdownOpen(!isDropdownOpen);
    //     console.log(isDropdownOpen);

    //   };

    return (
        <div className="flex flex-col min-h-screen bg-white text-[#2f2f2f]">
            <NavbarOakNorth />
            {!tosAccepted && !showForm ? (
                // Terms of Service Modal
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                    <div className="bg-white p-5 rounded-lg text-center">
                        <h2 className="text-2xl font-bold mb-4">Terms of Service</h2>
                        <p>Please accept our Terms of Service to continue using our site.</p>
                        <button 
                            onClick={acceptTerms}
                            className="mt-4 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700"
                        >
                            I Accept
                        </button>
                    </div>
                </div>
            ) : (
            <main className="flex-1 overflow-hidden">

                <section className="chat-page relative w-full py-4 md:py-10 lg:py-10 xl:py-10 bg-oaknorth-primary">
                    <div className="text-center mt-4 md:mt-0 flex-grow flex items-center justify-center">
                            {showForm ? (
                                <div className="space-y-5 px-4">
                                    <h1 className="text-5xl md:text-6xl lg:text-7xl font-aquawax mb-2 md:mb-4 px-4">
                                        Find the right fit
                                    </h1>
                                    <hr></hr>
                                    <p className="mx-auto max-w-[700px] text-[#2f2f2f] font-aquawax font-bold md:text-xl px-4">
                                        Apply in minutes.
                                    </p>
                                    
                                </div>
                            ) : (
                                <div className="space-y-5 px-4">
                                     <h1 className="text-5xl md:text-6xl lg:text-7xl font-aquawax tracking-tighter mb-2 md:mb-4 px-4">
                                        Fast-Track Your Loan Application
                                    </h1>
                                    <hr></hr>
                                    <p className="mx-auto max-w-[800px] text-[#2f2f2f] font-aquawax md:text-xl px-4">
                                    Take five minutes to answer a few questions.
                                    </p>
                                </div>
                            )}

                    </div>
                </section>

                {showForm ? (
                    <section className="flex flex-col justify-center items-center p-6">
                        <div className='pb-8'>
                            <p className="mx-auto max-w-[700px] text-black font-manrope px-4">
                                Checking your eligibility won't affect your credit rating.
                            </p>
                        </div>
                        
                        <form onSubmit={handleSubmit} className="w-full max-w-lg text-black">
                            <div className="flex flex-wrap -mx-3 mb-6">
                                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="first-name">
                                        First Name
                                    </label>
                                    <input 
                                        required 
                                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                                        id="first-name" 
                                        type="text" 
                                        placeholder="Jane" 
                                        value={firstName} 
                                        onChange={(e) => setFirstName(e.target.value)}
                                    />
                                </div>
                                <div className="w-full md:w-1/2 px-3">
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="last-name">
                                        Last Name
                                    </label>
                                    <input 
                                        required 
                                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                                        id="last-name" 
                                        type="text" 
                                        placeholder="Doe" 
                                        value={lastName} 
                                        onChange={(e) => setLastName(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-wrap -mx-3 mb-6">
                                <div className="w-full px-3">
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="emailAddress">
                                        Email
                                    </label>
                                    <input 
                                        required 
                                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                                        id="emailAddress" 
                                        type="email" 
                                        placeholder="jane.doe@acme.com" 
                                        value={emailAddress} 
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-wrap -mx-3 mb-6">
                                <div className="w-full px-3">
                                    {/* <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="companyName">
                                        Company Name
                                    </label>
                                    <input required className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="companyName" type="text" placeholder="Acme Inc" value={companyName} onChange={(e) => setCompany(e.target.value)}/> */}
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="companyName">
                                        Company Name
                                    </label>
                                    <input
                                        required
                                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                        id="companyName"
                                        type="text"
                                        placeholder="Acme Inc"
                                        value={companyName}
                                        onChange={(e) => {
                                            setCompany(e.target.value);
                                            fetchCompanySuggestions(e.target.value);
                                        }}
                                    />
                                    {companySuggestions.length > 0 && (
                                        <ul className="absolute z-10 list-disc bg-white border mt-1 max-h-60 overflow-auto">
                                            {companySuggestions.map((company, index) => (
                                                <li
                                                    key={index}
                                                    className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                                                    onClick={() => {
                                                        setCompany(company.title); // Set the company name
                                                        setCompanyNumber(company.company_number); // Also set the company number
                                                        setCompanySuggestions([]); // Clear suggestions
                                                    }}
                                                >
                                                    {company.title}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                            </div>
                            {companyNumber && (
                                <div className="flex flex-wrap -mx-3 mb-6">
                                    <div className="w-full px-3">
                                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="companyNumber">
                                            Company Number
                                        </label>
                                        <input 
                                            required 
                                            readOnly // Make it read-only if users shouldn't change it
                                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white" 
                                            id="companyNumber" 
                                            type="text" 
                                            value={companyNumber}
                                            onChange={(e) => setCompanyNumber(e.target.value)} // This handler can be removed if the field is read-only
                                        />
                                    </div>
                                </div>
                            )}
                            <div className="flex flex-wrap -mx-3 mb-6">
                                <div className="w-full px-3">
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="phoneNumber">
                                        Phone Number
                                    </label>
                                    <input required className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="phoneNumber" type="text" placeholder="+447012 345678" value={phoneNumber} onChange={(e) => setPhone(e.target.value)}/>
                                </div>
                            </div>
                            <div className="flex flex-wrap -mx-3 mb-6">
                                <div className="w-full px-3">
                                    <div className="flex items-center mb-4">
                                        <input id="email-updates" name="email-updates" type="checkbox" defaultChecked className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                        <label htmlFor="email-updates" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                            Keep in touch via email
                                        </label>
                                    </div>
                                    <div className="flex items-center"></div>
                                    <input required id="terms-of-service" name="terms-of-service" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                        <label htmlFor="terms-of-service" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                            Accept <a className="font-bold text-blue-900 hover:text-consilium-darker" href="https://junoai.notion.site/TERMS-OF-SERVICE-d6d27951622b4f5f9202de2394a7e6ad">Terms of Service</a>
                                        </label>
                                </div>
                            </div>
                            
                            <div className="flex justify-center">
                                <button className="shadow bg-black hover:bg-consilium-darker focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded" type="submit">
                                    Enquire
                                </button>
                            </div>
                        </form>
                    </section>
                ) : (
                    <div>
                        {showThankYouMessage ? (
                            <div className="flex flex-col min-h-[500px] justify-center items-center p-6 text-black">
                                <h2 className="text-3xl font-bold font-manrope mb-4">Thank You!</h2>
                                <p className="font-manrope">We've received your application and will be in touch soon.</p>
                            </div>
                            ) : (
                                <div>
                            <section className="relative chat-page flex-1 flex justify-center p-6 bg-white">
                                <div className="w-full md:w-2/3 lg:w-1/3 px-4 sm:px-6 overflow-hidden h-auto min-h-[500px] max-h- flex flex-col justify-center items-center">
                                    <iframe
                                        id="super_embed"
                                        title="superdash_embed"
                                        className="w-full mb-4 rounded-lg"
                                        src={iframeSrcUrl}
                                        style={{ minHeight: '500px' }} 
                                    ></iframe>
                                    <div className="relative w-full flex justify-end items-center">
                                        {isMenuOpen && (
                                            <button
                                                onClick={startAgain}
                                                className="text-black hover:bg-call-to-action hover:text-white border hover:border-white border-black font-bold font-manrope px-5 py-2.5 text-center mr-2 rounded"
                                            >
                                                Start Again
                                            </button>
                                        )}
                                        
                                        <button
                                            onClick={toggleMenu}
                                            className="text-black hover:bg-call-to-action hover:text-white border hover:border-white border-black font-bold font-manrope px-5 py-2.5 text-center mr-2 mb-2 rounded flex items-center"
                                        >
                                            <FaBars />
                                        </button>
                                    </div>
                                    {/* {isMenuOpen && (
                                        <div className="absolute top-full right-0 mt-2 z-10">
                                        <button
                                        onClick={startAgain}
                                        className="text-black hover:bg-call-to-action hover:text-white border hover:border-white border-black font-bold font-manrope px-5 py-2.5 text-center mr-2 mb-2 rounded"
                                    >
                                        Start Again
                                        </button> 
                                        </div>
                                    )} */}
                                    {/* <button
                                        // onClick={handleGetMyLoanClick}
                                        // className="text-white bg-black hover:bg-consilium-darker hover:text-black border border-black font-bold font-manrope px-5 py-2.5 text-center mr-2 mb-2 rounded"
                                        onClick={startAgain}
                                        className="text-black hover:bg-call-to-action hover:text-white border hover:border-white border-black font-bold font-manrope px-5 py-2.5 text-center mr-2 mb-2 rounded"
                                    >
                                        Start Again
                                    </button>  */}
                                   
                                   {/* <div className="relative w-full flex justify-end">
                                        <button
                                            onClick={toggleMenu}
                                            className="text-black hover:bg-call-to-action hover:text-white border hover:border-white border-black font-bold font-manrope px-5 py-2.5 text-center mr-2 mb-2 rounded flex items-center"
                                        >
                                            <FaBars /> 
                                        </button>

                                       
                                    </div> */}
                                   
                                </div>
                            </section> 
                            <section className="chat-page relative w-full py-4 md:py-10 lg:py-10 xl:py-10 bg-oaknorth-primary">
                                <div className="text-center mt-4 md:mt-0 flex-grow flex items-center justify-center">
                                    <div className="space-y-5 m-4">
                                        <p className="mx-auto max-w-[700px] text-[#2f2f2f] font-manrope md:text-xl">
                                            Rather chat on the phone?
                                        </p>
                                        <button 
                                        className="bg-transparent hover:bg-call-to-action text-[#2f2f2f] font-manrope hover:text-white py-2 px-4 border border-[#2f2f2f] hover:border-black rounded"
                                        onClick={handlePhoneRequestClick}
                                        >
                                            Talk to the team
                                        </button>
                                    </div>
                                </div>
                            </section>
                            </div>
                        )}
                    </div>
                    
                )}

            </main>
            )} 
            <footer className="flex flex-col gap-2 sm:flex-row py-6 w-full shrink-0 items-center px-4 md:px-6 border-t border-black bg-black">
                <p className="text-xs text-white">© Juno. All rights reserved.</p>
                <nav className="sm:ml-auto flex gap-4 sm:gap-6">
                    <Link className="text-xs text-white hover:underline underline-offset-4" to="https://www.termsfeed.com/live/868ca480-60e5-4091-a7dd-3856efc2c37d">
                        Terms of Service
                    </Link>
                    <Link className="text-xs text-white hover:underline underline-offset-4" to="https://www.termsfeed.com/live/4f3ebefe-fb02-4b06-818c-66a22151d721">
                        Privacy
                    </Link>
                </nav>
            </footer>
            
            <CookieConsent
                location="bottom"
                buttonText="Okay"
                style={{ background: "black", color: "white" }}
                buttonStyle={{ background: "white", color: "black", fontSize: "13px" }}
                expires={150}
            >
                This website uses cookies to enhance the user experience.
            </CookieConsent>
        </div>
    )
}

export default ChatPageOakNorth;
