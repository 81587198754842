// require('dotenv').config();

// const dotenv = require('dotenv');

// Asynchronously posts the userIdentifier to an API endpoint
export const postUserIdentifier = async (userIdentifier) => {
    console.log('posting useridentifier', userIdentifier)
    const apiUrl = 'https://api.superdashhq.com/api/v1/webhookstatus/extractConversationDataUsingUserIdentifier/'+ process.env.REACT_APP_OAKNORTH_SUPERDASH_PROJECT_ID + '/' + userIdentifier; // Replace with your actual API endpoint URL
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': process.env.REACT_APP_OAKNORTH_SUPERDASH_API_KEY

        },
        // body: JSON.stringify({ userIdentifier }),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      console.log('User identifier posted to extraction API');
      return response
      // return true;
    } catch (error) {
      console.error('Error posting user identifier:', error);
    }
  };

export const postUserIdentifierBeacon = (userParams) => {
  console.log("POSTING BECON");
    const apiUrl = 'https://80r1uiiwg8.execute-api.eu-west-2.amazonaws.com/ConsiliumDeploymentStage/triggerExtraction'; // Replace with your actual API endpoint URL
    userParams.projectId = process.env.REACT_APP_OAKNORTH_SUPERDASH_PROJECT_ID;
    userParams.api_key = process.env.REACT_APP_OAKNORTH_SUPERDASH_API_KEY;
    const data = JSON.stringify(userParams);
    // const data = JSON.stringify({ 'userIdentifier': userIdentifier, 'projectId': process.env.REACT_APP_SUPERDASH_PROJECT_ID, 'api_key': process.env.REACT_APP_SUPERDASH_API_KEY });
    
    // Use the Beacon API to send the data
    navigator.sendBeacon(apiUrl, data);
}




  export const sendUserDetails = async (userParams) => {
    const url = process.env.REACT_APP_OAKNORTH_INGEST_USER_DETAILS_URL;
  
    const headers = {
      "Content-Type": "application/json",
      "x-api-key": process.env.REACT_APP_OAKNORTH_USER_DETAILS_KEY // user details key
    };
  
    try {
      const userParamsBody = {'body': JSON.stringify(userParams)};
      console.log(userParamsBody);
      const response = await fetch(url, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(userParamsBody),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      console.log("received: ", data);
      // const body = JSON.parse(data.body);
      // console.log('Response Data:', data);
      // console.log("Response ")
      // console.log("Received insertedID:", body.inserted_id);
      return data.body.inserted_id; // Assuming the response JSON contains the inserted_id field directly
    } catch (error) {
      console.error("Error sending user details:", error);
    }
  }

  export const sendPhoneRequest = async (userParams) => {
    const url = process.env.REACT_APP_OAKNORTH_INGEST_DATA_URL;
  
    const headers = {
      "Content-Type": "application/json",
      "x-api-key": process.env.REACT_APP_OAKNORTH_INGEST_DATA_KEY 
    };
  
    try {
        userParams.conversationId = userParams.userIdentifier;
        userParams.phoneRequest = true;

        const userParamsBody = JSON.stringify(userParams);
        console.log('submitting userParams:', userParamsBody);

        const response = await fetch(url, {
          method: 'POST',
          headers: headers,
          body: JSON.stringify(userParamsBody),
        });
  
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
  
        const data = await response.json();
        console.log('Response Data:', data);
        return data.body.inserted_id; // Assuming the response JSON contains the inserted_id field directly

    } catch (error) {
      console.error("Error sending user details:", error);
    }
  }


// receive conversationHistory from superdash API using GET https://api.superdashhq.com/api/v1/conversation/project/65f1482ab13952744fc4702a/:userIdentifier
// test userIdentifier: 66154a0c3c1b641d7ce6b2d9

// console.log('updatingConversationHistory')
// console.log('posting conversation:', receivedData.data.conversationHistory);
// var targetOrigin = "https://superdashhq.com";
                   
// iframe.contentWindow.postMessage({
//   action: "updateConversations",
//   conversations: receivedData.data.conversationHistory
// }, targetOrigin);
  

//   phone request flow
// 1. attempt conversation extraction using postUserIdentifier
// 2. if response != 200, send details directly to ingest API

export const getConversationByUserIdentifier = async (userIdentifier) => {
  const apiUrl = 'https://api.superdashhq.com/api/v1/conversation/project/'+ process.env.REACT_APP_OAKNORTH_SUPERDASH_PROJECT_ID + '/' + userIdentifier; 
  try {
    const response = await fetch(apiUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': process.env.REACT_APP_OAKNORTH_SUPERDASH_API_KEY

      },
      // body: JSON.stringify({ userIdentifier }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    // console.log('Retrieved conversation: ', data);
    return data.messages;
    // let responseObj = response.json()
    // console.log("messages:", responseObj);
  } catch (error) {
    console.error('Error retrieving conversation', error);
    return null
  }
};
