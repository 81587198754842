// PasswordPage.js
import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import crypto from 'crypto-js';
import './PasswordPage.css';

const PasswordPage = ({ setAuthenticated }) => {
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const redirectTo = `/oaknorth?${queryParams.toString()}`;

  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    const hashedPassword = crypto.SHA256(password).toString();
    const correctHash = crypto.SHA256('thisisjuno2024').toString(); // replace 'your_password_here' with the actual password

    if (hashedPassword === correctHash) {
      setAuthenticated(true);
      localStorage.setItem('authToken', hashedPassword); // Store the hashed password in localStorage
      navigate(redirectTo); // Redirect to the protected page with original query params
    } else {
      alert('Incorrect password');
    }
  };

  return (
    <div className="password-container">
      <div className="password-box">
        <h2>Enter Password</h2>
        <form onSubmit={handlePasswordSubmit}>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            required
          />
          <button type="submit">Submit</button>
        </form>
      </div>
    </div>
  );
};

export default PasswordPage;
