// import React from "react";
// import { HashLink as Link } from 'react-router-hash-link';

// const Navbar = () => {
//   // Event handler to clear localStorage and sessionStorage
//   // const handleClearStorage = () => {
//   //   localStorage.clear();
//   //   sessionStorage.clear();
//   //   window.location.href = '/';
//   // };

//   return (
//     // <nav className="bg-consilium-primary p-4">
//     // <nav className="bg-consilium-darker p-4">
//     <nav className="bg-white p-4">
//       <div className="container mx-auto flex justify-between items-center">
//         <Link to="/chat" className="flex items-center">
//           <img 
//             src="/consilium_logo_2.png" 
//             alt="Logo" 
//             className="h-8 md:h-10 lg:h-12"
//           />
//         </Link>
//         {/* Start Again Button */}
//         {/* <button
//           onClick={handleClearStorage}
//           className="bg-transparent hover:bg-consilium-darker text-consilium-text hover:text-white py-2 px-4 border border-consilium-text hover:border-transparent rounded"
//         >
//           Start Again
//         </button> */}
//       </div>
//     </nav>
//   );
// };

// export default Navbar;

import React from "react";
import { HashLink as Link } from 'react-router-hash-link';

const Navbar = () => {
    // const [isOpen, setIsOpen] = React.useState(false);

    return (
        <nav className="border-b border-blue-700 
        bg-[#fdfcf3]
        shadow-2xl
        z-1 relative">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex items-center justify-between h-16">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                    <Link to="/" className="flex items-center">
                        {/* Logo */}
                        <img src="logo512.png" alt="Logo" className="h-10 w-10" /> {/* Adjust the image URL and size as needed */}
                        {/* Brand Name */}
                        <span className="font-bold font-manrope text-4xl text-blue-900 tracking-tight">Juno</span>
                    </Link>
                  
                </div>
              </div>
              <div className="hidden md:flex ml-auto"> {/* Use ml-auto to push navigation links to the right */}
                <div className="flex items-baseline space-x-4">
                  {/* Navigation Links for large screens */}
                  {/* <Link to="/#about" className="px-3 py-2 rounded-md text-sm font-medium hover:underline">About</Link>
                  <Link to="/#team" className="px-3 py-2 rounded-md text-sm font-medium hover-underline">Team</Link> */}
                  {/* <Link
                                to="/#waitlist"
                                className="bg-transparent hover:bg-blue-500 text-blue-500 font-medium font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
                                >
                                Join Waitlist
                            </Link> */}
                </div>
              </div>
              {/* <div className="md:hidden">
                <button onClick={() => setIsOpen(!isOpen)} type="button" className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700">
                  <svg className="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
                  </svg>
                </button>
              </div> */}
            </div>
          </div>

          {/* <div className={`fixed top-0 right-0 w-64 h-full bg-white text-black z-50 transform ease-in-out duration-300 ${isOpen ? 'translate-x-0' : 'translate-x-full'}`}>
            <div className="p-4">
              <button onClick={() => setIsOpen(false)} className="text-gray-700 hover:text-gray-900">
                <svg className="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
            <div className="px-2 space-y-1">
              <Link to="/#about" onClick={() => setIsOpen(false)} className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:underline">About</Link>
              <Link to="/#team" onClick={() => setIsOpen(false)} className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:underline">Team</Link>
            </div>
          </div> */}
        </nav>
    );
};

export default Navbar;

