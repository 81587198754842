// src/components/ThreeDAnimation.js
import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';

const ThreeDAnimation = () => {
  const mountRef = useRef(null);

  useEffect(() => {
    const mount = mountRef.current;

    // Set up the scene, camera, and renderer
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });

    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.setClearColor(0x000000, 0); // Set background to transparent
    mount.appendChild(renderer.domElement);

    const handleResize = () => {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(window.innerWidth, window.innerHeight);
    };

    // Handle window resize
    window.addEventListener('resize', handleResize);

    // Create nodes (spheres)
    const nodes = [];
    const nodeMaterial = new THREE.MeshStandardMaterial({ color: 0x888888 });

    for (let i = 0; i < 10; i++) {
      const nodeGeometry = new THREE.SphereGeometry(1.4, 32, 32); // Double the size of nodes
      const node = new THREE.Mesh(nodeGeometry, nodeMaterial);
      node.position.set(
        (Math.random() - 0.5) * 90, // Double the range
        (Math.random() - 0.5) * 30,
        (Math.random() - 0.5) * 30
      );
      nodes.push(node);
      scene.add(node);
    }

    // Create edges (lines)
    const edges = [];
    const edgeMaterial = new THREE.LineBasicMaterial({ color: 0xcccccc });

    for (let i = 0; i < nodes.length; i++) {
      for (let j = i + 1; j < nodes.length; j++) {
        const edgeGeometry = new THREE.BufferGeometry().setFromPoints([
          nodes[i].position,
          nodes[j].position,
        ]);
        const edge = new THREE.Line(edgeGeometry, edgeMaterial);
        edges.push(edge);
        scene.add(edge);
      }
    }

    // Add lighting
    const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
    scene.add(ambientLight);
    const pointLight = new THREE.PointLight(0xffffff, 1);
    pointLight.position.set(10, 10, 10);
    scene.add(pointLight);

    camera.position.z = 50; // Adjust camera position for larger graph

    // Animation loop
    const animate = () => {
      requestAnimationFrame(animate);

      scene.rotation.x += 0.002;
      scene.rotation.y += 0.004;

      renderer.render(scene, camera);
    };

    animate();

    // Clean up on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
      mount.removeChild(renderer.domElement);
    };
  }, []);

  return <div ref={mountRef} style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', filter: 'blur(8px)', zIndex: 0 }} />;
};

export default ThreeDAnimation;
