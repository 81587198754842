import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import Navbar from './Navbar';
import CookieConsent from "react-cookie-consent";
import './ChatPage.css';
import { useEffect, useState } from 'react';
import { postUserIdentifier } from './utils';
import { sendUserDetails } from './utils';
import { sendPhoneRequest } from './utils';
import { postUserIdentifierBeacon } from './utils';
import { getConversationByUserIdentifier } from './utils';
import { debounce } from 'lodash';

function ChatPage() {

    const [userIdentifier, setUserIdentifier] = useState('');
    const [showForm, setShowForm] = useState(false);
    const [tosAccepted, setTosAccepted] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [emailAddress, setEmail] = useState('');
    const [companyName, setCompany] = useState('');
    const [phoneNumber, setPhone] = useState('');
    const [showThankYouMessage, setShowThankYouMessage] = useState(false);
    const [companySuggestions, setCompanySuggestions] = useState([]);
    const [companyNumber, setCompanyNumber] = useState('');

    // const [isLoading, setIsLoading] = useState(false);


    useEffect(() => {

        // console.log('test env:', process.env.TEST_ENV);

        // const manageUserIdentiferFromURL = async () => {
        //     const queryParams = new URLSearchParams(window.location.search);
        //     const userIdentiferFromURL = queryParams.get('userIdentifier');

        //     if (userIdentiferFromURL && userIdentiferFromURL.length > 0) {
        //         try {
        //             const response = await getConversationByUserIdentifier(userIdentiferFromURL);
        //             if (response && response.ok) {
        //                 localStorage.setItem('userIdentifier', userIdentiferFromURL);

        //                 const conversationDetailsObj = await response.json()
        //                 const messages = conversationDetailsObj.messages;
        //                 if (messages) {
        //                     const iframe = document.getElementById('super_embed');
                    
        //                     var targetOrigin = "https://www.superdashhq.com";
                                                
        //                     console.log("posting messages...", messages)
        //                     iframe.contentWindow.postMessage({
        //                         action: "updateConversations",
        //                         conversations: messages
        //                     }, targetOrigin);
                
        //                     console.log('Posted messages:', messages);
                
        //                   }
        //             } else {
        //                 console.error('Failed to retrieve conversation for user');
        //             }
        //         } catch (error) {
        //             console.error('Error in retrieving conversation:', error);
        //         }
        //     }
        // };

        const manageUserIdentiferFromURL = async () => {
            const queryParams = new URLSearchParams(window.location.search);
            const userIdentiferFromURL = queryParams.get('userIdentifier');
        
            if (userIdentiferFromURL && userIdentiferFromURL.length > 0) {
                try {
                    const response = await getConversationByUserIdentifier(userIdentiferFromURL);
        
                    if (response && response.ok) {
                        localStorage.setItem('userIdentifier', userIdentiferFromURL);
                        const conversationDetailsObj = await response.json();
                        const messages = conversationDetailsObj.messages;
        
                        if (messages) {
                            const iframe = document.getElementById('super_embed');
                            if (!iframe) {
                                return console.error('Iframe not found');
                            }

                            iframe.onerror = (event) => {
                                console.error('Iframe failed to load:', event);
                            };

                            // Set onload handler
                            iframe.onload = () => {
                                var targetOrigin = "https://www.superdashhq.com";
                                console.log('iframe ready', iframe);
                                iframe.contentWindow.postMessage({
                                    action: "updateConversations",
                                    conversations: messages
                                }, targetOrigin);
                                console.log('Posted messages:', messages);
                            };

                            // Check if iframe is already loaded and trigger onload if so
                            if (iframe.src && iframe.complete) {
                                iframe.onload();
                            }
                        }
                    } else {
                        console.error('Failed to retrieve conversation for user');
                    }
                } catch (error) {
                    console.error('Error in retrieving conversation:', error);
                }
            }
        };

        window.addEventListener("message", (event) => {
            // Check the origin to ensure it matches your iframe's origin
            console.log("Received message from:", event.origin);
            console.log("Message source:", event.source);
            console.log("Message data:", event.data);

            if (event.origin !== "https://www.superdashhq.com") {
                console.log("Received a message from an unauthorized origin.", event.origin);
                return; // Stop processing if the origin doesn't match
            }
        
            // Process the message if it is the expected type
            if (event.data && event.data.action === "getConversationData") {
                console.log("Received 'getConversationData' event:", event.data);
        
                // Handle the data from the event
                // handleConversationData(event.data);
            }
        }, false);
        

        // const manageUserIdentiferFromURL = async () => {
        //     const queryParams = new URLSearchParams(window.location.search);
        //     const userIdentiferFromURL = queryParams.get('userIdentifier');

        //     console.log('userIdentifier from URL: ', userIdentiferFromURL);

        //     if (userIdentiferFromURL && userIdentiferFromURL.length > 0) {
        //         try {
        //             const response = await getConversationByUserIdentifier(userIdentiferFromURL);
        //             if (response && response.ok) {
        //                 localStorage.setItem('userIdentifier', userIdentiferFromURL);

                    
        //                 const conversationDetailsObj = await response.json()
        //                 const messages = conversationDetailsObj.messages;
        //                 console.log('posting message history...', messages);

        //                 if (messages) {
        //                     const iframe = document.getElementById('super_embed');
                
        //                     var targetOrigin = "https://www.superdashhq.com";
        //                     console.log('iframe:', iframe);
        //                     iframe.contentWindow.postMessage({
        //                         action: "updateConversations",
        //                         // conversations: JSON.stringify(messages)
        //                         conversations: messages
        //                     }, targetOrigin);
                
        //                     console.log('Posted messages:', messages);
                
        //                   }
        //             } else {
        //                 console.error('Failed to retrieve conversation for user');
        //             }
        //         } catch (error) {
        //             console.error('Error in retrieving conversation:', error);
        //         }
        //     }
        // };

        // Function to retrieve userParams from localStorage and send them
        // const loadAndSendUserDetails = async () => {
        //     await manageUserIdentiferFromURL();
        //     // Retrieve userParams from localStorage
        //     const userParamsJson = localStorage.getItem('userParams');
            
        //     if (userParamsJson) {
        //         const userParams = JSON.parse(userParamsJson);
                
        //         // Send userParams using the sendUserDetails function
        //         try {
        //             const insertedId = await sendUserDetails(userParams);
        //             console.log('Inserted ID:', insertedId);
        //             // Further actions based on response, if needed

        //             let storedUserIdentifier = localStorage.getItem('userIdentifier');
        //             console.log('storedUserIdentifier:', storedUserIdentifier);
        //             if (!storedUserIdentifier) {
        //                 storedUserIdentifier = insertedId;
        //                 localStorage.setItem('userIdentifier', storedUserIdentifier);
        //             }
        //             setUserIdentifier(storedUserIdentifier);
        //         } catch (error) {
        //             console.error("Error sending user details:", error);
        //         }
        //     }
        // };
        // loadAndSendUserDetails();

        // Function to retrieve userParams from localStorage and send them
        const loadAndSendUserDetails = async () => {
            await manageUserIdentiferFromURL();
            // Retrieve userParams from localStorage
            const userParamsJson = localStorage.getItem('userParams');
            
            if (userParamsJson) {
                const userParams = JSON.parse(userParamsJson);
                // console.log("userParams: ", userParams);
            
                // Send userParams using the sendUserDetails function
                try {
                    const insertedId = await sendUserDetails(userParams);
                    console.log('Inserted ID:', insertedId);
                    // Further actions based on response, if needed

                    
                    let storedUserIdentifier = localStorage.getItem('userIdentifier');
                    if (!storedUserIdentifier) {
                        storedUserIdentifier = insertedId;
                        localStorage.setItem('userIdentifier', storedUserIdentifier);
                    }
                    setUserIdentifier(storedUserIdentifier);
                } catch (error) {
                    console.error("Error sending user details:", error);
                }
            } else {
                // get userParams from url instead and sendUserDetails()
                const urlParams = new URLSearchParams(window.location.search);
                const userParams = {
                    companyName: urlParams.get('companyName'),
                    emailAddress: urlParams.get('emailAddress'),
                    firstName: urlParams.get('firstName'),
                    lastName: urlParams.get('lastName'),
                    phoneNumber: urlParams.get('phoneNumber'),
                    companyNumber: urlParams.get('companyNumber')
                };

                // Send userParams using the sendUserDetails function
                try {
                    const insertedId = await sendUserDetails(userParams);
                    console.log('Inserted ID:', insertedId);
                    } catch (error) {
                        console.error("Error sending user details from URL:", error);
                    }
                }
        };
        loadAndSendUserDetails();

        const requiredParams = ['firstName', 'lastName', 'emailAddress', 'companyName', 'phoneNumber'];
        const queryParams = new URLSearchParams(window.location.search);
        setFirstName(queryParams.get('firstName') || '');
        setLastName(queryParams.get('lastName') || '');
        setEmail(queryParams.get('emailAddress') || '');
        setCompany(queryParams.get('companyName') || '');
        setPhone(queryParams.get('phoneNumber') || '');
        setCompanyNumber(queryParams.get('companyNumber') || '');
        const userParams = JSON.parse(localStorage.getItem('userParams'));

        // const isParamMissingInURL = requiredParams.some(param => !queryParams.has(param));
        const isParamMissingInLocalStorage = requiredParams.some(param => !(userParams && userParams[param]));

        // if (queryParams.get('userIdentifier')) {
        //     setShowForm(false);
        // } else {
        //     setShowForm(isParamMissingInLocalStorage);
        // }
    
        setShowForm(isParamMissingInLocalStorage);

        const tosAccepted = sessionStorage.getItem('tosAccepted') === 'true';
        setTosAccepted(tosAccepted);

        const handleBeforeUnload = (event) => {
            const userIdentifier = localStorage.getItem('userIdentifier'); // Assuming userIdentifier is stored in localStorage
            if (userIdentifier) {
            // postUserIdentifier(userIdentifier);
                postUserIdentifierBeacon(userIdentifier);
              console.log('posted')
              // Depending on browser support and requirements, you might not see console logs for async operations in beforeunload
            }
          };
        
          window.addEventListener('beforeunload', handleBeforeUnload);
        
          return () => {
             window.removeEventListener('beforeunload', handleBeforeUnload);
          };

          
    }, []);

    console.log('userIdentifier:', userIdentifier);

    const userParams = JSON.parse(localStorage.getItem('userParams') || '{}');
    const iframeSrcBaseUrl = "https://www.superdashhq.com/embed?projectID=65fc2be19e6e4353ee27d7df";
    const iframeParams = new URLSearchParams({
        ...userParams,
        userIdentifier: userIdentifier,
    });

    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('clearLocalStorage')) {
        // Add clearLocalStorage=true parameter to iframeParams
        console.log('clearLocalStorage detected')
        iframeParams.set('clearLocalStorage', 'true');
    }
    // then refresh without the clearLocalStorage param
    

    // const iframeSrcUrl = `${iframeSrcBaseUrl}&${iframeParams}`;
    const iframeSrcUrl = `${iframeSrcBaseUrl}&${iframeParams.toString()}`;
    console.log('iframe URL:', iframeSrcUrl);

    // console.log(iframeSrcUrl);

    const acceptTerms = () => {
        sessionStorage.setItem('tosAccepted', 'true');
        setTosAccepted(true);
    };

    // const handleGetMyLoanClick = () => {
    //     postUserIdentifier(userIdentifier)
    //     // postUserIdentifierBeacon(userIdentifier)
    //     // console.log('posted');
    //       .then(() => {
    //         console.log('User identifier posted successfully');
    //         setShowThankYouMessage(true);
    //       }) 
    //       .catch((error) => console.error('Failed to post user identifier', error));
    //   };

    const handlePhoneRequestClick = () => {
        postUserIdentifier(userIdentifier)
            .then(response => {
                setShowThankYouMessage(true);
                // Assuming postUserIdentifier is adjusted to resolve with the response object
                console.log('postUserIdentifier response:', response);
                if (response.ok) {
                    console.log('User identifier posted successfully');
                } else if (response.status === 400) {
                    // Handle 400 response specifically
                    console.log('bad request:', response)
                    // handleBadRequest(); // This is a function you would define to handle a 400 Bad Request response.
                }
            }) 
            .catch((error) => {
                console.error('Failed to post user identifier on phoneRequest', error);
                // Additional error handling here if needed
                console.log('send userParams', userParams);
                sendPhoneRequest(userParams);
                setShowThankYouMessage(true);
            }); 
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Gather form values
        const firstName = e.target.elements['first-name'].value;
        const lastName = e.target.elements['last-name'].value;
        const emailAddress = e.target.elements['emailAddress'].value;
        const companyName = e.target.elements['companyName'].value;
        const phoneNumber = e.target.elements['phoneNumber'].value;
        const emailUpdates = e.target.elements['email-updates'].checked.toString();
        const termsOfService = e.target.elements['terms-of-service'].checked.toString();
        const companyNumber = e.target.elements['companyNumber'] ? e.target.elements['companyNumber'].value : '';

        // Construct the data object conditionally
        let data = {
            firstName,
            lastName,
            emailAddress,
            companyName,
            phoneNumber,
            emailUpdates,
            // termsOfService, // Uncomment if needed
        };

        // Include companyNumber only if it exists
        if (companyNumber) {
            data.companyNumber = companyNumber;
        }

        // Construct URL with query parameters from data object
        const queryParams = new URLSearchParams(data);

        const urlParams = new URLSearchParams(window.location.search);
        // console.log(urlParams);
        if (urlParams.has('clearLocalStorage')) {
            // Add clearLocalStorage=true parameter to iframeParams
            // console.log('clearLocalStorage detected')
            queryParams.set('clearLocalStorage', 'true');
        }

        if (urlParams.has('userIdentifier')) {
            queryParams.set('userIdentifier', urlParams.get('userIdentifier'));
        }

        // Save to localStorage
        localStorage.setItem('userParams', JSON.stringify(data));

        sessionStorage.setItem('tosAccepted', termsOfService);
        window.location.href = `${window.location.pathname}?${queryParams.toString()}`;
    };

    // Fetch company suggestions
    const fetchCompanySuggestions = debounce(async (query) => {
        if (!query) {
            setCompanySuggestions([]);
            return;
        }

        try {
            const response = await fetch(`/api/proxy?query=${encodeURIComponent(query)}`);
            if (!response.ok) throw new Error('Failed to fetch');

            // console.log('response', await response.text());
            const suggestions = await response.json();

            // console.log('suggestions:', suggestions);
            
            setCompanySuggestions(suggestions.items);
        } catch (error) {
            console.error("Error fetching company suggestions:", error);
        }
    }, 300); // Adjust debounce timing as needed

    // const startAgain = () => {
    //     // Clear local storage
    //     localStorage.clear();
        
    //     // Add clearLocalStorage=true parameter to iframeSrcUrl
    //     const updatedIframeSrcUrl = `${iframeSrcUrl}&clearLocalStorage=true`;
    //     // console.log('updated src:', updatedIframeSrcUrl);

    //     const iframe = document.getElementById('super_embed');

    //     // Reload the iframe content with the updated URL
    //     iframe.src = updatedIframeSrcUrl;

    //     const currentUrl = window.location.href;
    //     // const updatedUrl = new URL(currentUrl);
    //     // updatedUrl.searchParams.set('clearLocalStorage', 'true');

    //     // Reload the page with the updated URL
    //     window.location.href = currentUrl.toString();

    // };

    const startAgain = () => {
        // Clear local storage
        localStorage.clear();
        console.log('Console cleared');
        
        // Add clearLocalStorage=true parameter to iframeSrcUrl
        const updatedIframeSrcUrl = `${iframeSrcUrl}&clearLocalStorage=true`;
        
        const iframe = document.getElementById('super_embed');
    
        // Define a function to handle page reload
        const reloadPage = () => {
            const currentUrl = window.location.href;
            // Reload the page with the updated URL
            window.location.href = currentUrl.toString();
        };
    
        // Set onload event listener for the iframe
        iframe.onload = () => {
            // Call reloadPage function after iframe finishes loading
            reloadPage();
        };
    
        // Reload the iframe content with the updated URL
        iframe.src = updatedIframeSrcUrl;
    };

    // async function fetchConversation(userIdentifier) {
    //     try {
    //       const conversationDetails = await getConversationByUserIdentifier(userIdentifier);
    //       const conversationDetailsObj = await conversationDetails.json()
    //       const messages = conversationDetailsObj.messages
    //       console.log("retrieved messages:", messages);
      
    //       if (messages) {
    //         const iframe = document.getElementById('super_embed');
    //         console.log("iframe:", iframe);

    //         var targetOrigin = "https://www.superdashhq.com";
                                
    //         iframe.contentWindow.postMessage({
    //             action: "updateConversations",
    //             conversations: JSON.stringify(conversationDetails)
    //         }, targetOrigin);

    //         console.log('Conversation Details:', conversationDetails);

    //       } else {
    //         console.log('No conversation details found or error occurred');
    //       }
    //     } catch (error) {
    //       console.error('Failed to fetch conversation:', error);
    //     }
    //   }

    //   Call the function
    // fetchConversation('663b51be60081012e8b6235d');
    // const urlParams2 = new URLSearchParams(window.location.search);
    // console.log(urlParams2.get('userIdentifier'));

    return (
        <div className="flex flex-col min-h-screen bg-white text-black">

            <Navbar />
            {!tosAccepted && !showForm ? (
                // Terms of Service Modal
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                    <div className="bg-white p-5 rounded-lg text-center">
                        <h2 className="text-2xl font-bold mb-4">Terms of Service</h2>
                        <p>Please accept our Terms of Service to continue using our site.</p>
                        <button 
                            onClick={acceptTerms}
                            className="mt-4 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700"
                        >
                            I Accept
                        </button>
                    </div>
                </div>
            ) : (
            <main className="flex-1 overflow-hidden">

                <section className="chat-page relative w-full py-4 md:py-10 lg:py-10 xl:py-10 bg-consilium-primary">
                    <div className="text-center mt-4 md:mt-0 flex-grow flex items-center justify-center">
                            {showForm ? (
                                <div className="space-y-5 px-4">
                                    <h1 className="text-5xl md:text-6xl lg:text-7xl font-manrope font-bold tracking-tighter mb-2 md:mb-4 px-4">
                                        Find the right fit
                                    </h1>
                                    <hr></hr>
                                    <p className="mx-auto max-w-[700px] text-black font-manrope font-bold md:text-xl px-4">
                                        Apply in minutes.
                                    </p>
                                    
                                </div>
                            ) : (
                                <div className="space-y-5 px-4">
                                     <h1 className="text-5xl md:text-6xl lg:text-7xl font-manrope font-bold tracking-tighter mb-2 md:mb-4 px-4">
                                        Fast-Track Your Application
                                    </h1>
                                    <hr></hr>
                                    <p className="mx-auto max-w-[700px] text-black font-manrope md:text-xl px-4">
                                        Just answer a few more questions in the chat below
                                    </p>
                                </div>
                            )}

                    </div>
                </section>

                {showForm ? (
                    <section className="flex flex-col justify-center items-center p-6 bg-[#fdfcf3]">
                        <div className='pb-8'>
                            <p className="mx-auto max-w-[700px] text-black font-manrope px-4">
                                Checking your eligibility won't affect your credit rating.
                            </p>
                        </div>
                        
                        <form onSubmit={handleSubmit} className="w-full max-w-lg">
                            <div className="flex flex-wrap -mx-3 mb-6">
                                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="first-name">
                                        First Name
                                    </label>
                                    <input 
                                        required 
                                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                                        id="first-name" 
                                        type="text" 
                                        placeholder="Jane" 
                                        value={firstName} 
                                        onChange={(e) => setFirstName(e.target.value)}
                                    />
                                </div>
                                <div className="w-full md:w-1/2 px-3">
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="last-name">
                                        Last Name
                                    </label>
                                    <input 
                                        required 
                                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                                        id="last-name" 
                                        type="text" 
                                        placeholder="Doe" 
                                        value={lastName} 
                                        onChange={(e) => setLastName(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-wrap -mx-3 mb-6">
                                <div className="w-full px-3">
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="emailAddress">
                                        Email
                                    </label>
                                    <input 
                                        required 
                                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                                        id="emailAddress" 
                                        type="email" 
                                        placeholder="jane.doe@acme.com" 
                                        value={emailAddress} 
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-wrap -mx-3 mb-6">
                                <div className="w-full px-3">
                                    {/* <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="companyName">
                                        Company Name
                                    </label>
                                    <input required className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="companyName" type="text" placeholder="Acme Inc" value={companyName} onChange={(e) => setCompany(e.target.value)}/> */}
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="companyName">
                                        Company Name
                                    </label>
                                    <input
                                        required
                                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                        id="companyName"
                                        type="text"
                                        placeholder="Acme Inc"
                                        value={companyName}
                                        onChange={(e) => {
                                            setCompany(e.target.value);
                                            fetchCompanySuggestions(e.target.value);
                                        }}
                                    />
                                    {companySuggestions.length > 0 && (
                                        <ul className="absolute z-10 list-disc bg-white border mt-1 max-h-60 overflow-auto">
                                            {companySuggestions.map((company, index) => (
                                                <li
                                                    key={index}
                                                    className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                                                    onClick={() => {
                                                        setCompany(company.title); // Set the company name
                                                        setCompanyNumber(company.company_number); // Also set the company number
                                                        setCompanySuggestions([]); // Clear suggestions
                                                    }}
                                                >
                                                    {company.title}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                            </div>
                            {companyNumber && (
                                <div className="flex flex-wrap -mx-3 mb-6">
                                    <div className="w-full px-3">
                                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="companyNumber">
                                            Company Number
                                        </label>
                                        <input 
                                            required 
                                            readOnly // Make it read-only if users shouldn't change it
                                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white" 
                                            id="companyNumber" 
                                            type="text" 
                                            value={companyNumber}
                                            onChange={(e) => setCompanyNumber(e.target.value)} // This handler can be removed if the field is read-only
                                        />
                                    </div>
                                </div>
                            )}
                            <div className="flex flex-wrap -mx-3 mb-6">
                                <div className="w-full px-3">
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="phoneNumber">
                                        Phone Number
                                    </label>
                                    <input required className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="phoneNumber" type="text" placeholder="+447012 345678" value={phoneNumber} onChange={(e) => setPhone(e.target.value)}/>
                                </div>
                            </div>
                            <div className="flex flex-wrap -mx-3 mb-6">
                                <div className="w-full px-3">
                                    <div className="flex items-center mb-4">
                                        <input id="email-updates" name="email-updates" type="checkbox" defaultChecked className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                        <label htmlFor="email-updates" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                            Keep in touch via email
                                        </label>
                                    </div>
                                    <div className="flex items-center"></div>
                                    <input required id="terms-of-service" name="terms-of-service" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                        <label htmlFor="terms-of-service" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                            Accept <a className="font-bold text-blue-900 hover:text-consilium-darker" href="https://junoai.notion.site/TERMS-OF-SERVICE-d6d27951622b4f5f9202de2394a7e6ad">Terms of Service</a>
                                        </label>
                                </div>
                            </div>
                            
                            <div className="flex justify-center">
                                <button className="shadow bg-black hover:bg-consilium-darker focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded" type="submit">
                                    Enquire
                                </button>
                            </div>
                        </form>
                    </section>
                ) : (
                    <div>
                        {showThankYouMessage ? (
                            <div className="flex flex-col min-h-[500px] justify-center items-center p-6">
                                <h2 className="text-3xl font-bold font-manrope mb-4">Thank You!</h2>
                                <p className="font-manrope">We've received your application and will be in touch soon.</p>
                            </div>
                            ) : (
                                <div>
                            {/* <section className="relative chat-page flex-1 flex justify-center p-6 bg-white"> */}
                            <section className="relative chat-page flex-1 flex justify-center p-6 bg-[#fdfcf3] bg-[linear-gradient(to_right,#80808012_1px,transparent_1px),linear-gradient(to_bottom,#80808012_1px,transparent_1px)] bg-[size:120px_120px]" >
                                <div className="w-full md:w-2/3 lg:w-1/3 px-4 sm:px-6 overflow-hidden h-auto min-h-[500px] max-h- flex flex-col justify-center items-center">
                                    <iframe
                                        id="super_embed"
                                        title="superdash_embed"
                                        className="w-full mb-4 rounded-lg"
                                        src={iframeSrcUrl}
                                        style={{ minHeight: '500px' }} 
                                    ></iframe>
                                     <button
                                        // onClick={handleGetMyLoanClick}
                                        // className="text-white bg-black hover:bg-consilium-darker hover:text-black border border-black font-bold font-manrope px-5 py-2.5 text-center mr-2 mb-2 rounded"
                                        onClick={startAgain}
                                        className="text-black hover:bg-consilium-darker hover:text-black border border-black font-bold font-manrope px-5 py-2.5 text-center mr-2 mb-2 rounded"
                                    >
                                        Start Again
                                    </button>  
                                </div>
                            </section> 
                            <section className="chat-page relative w-full py-4 md:py-10 lg:py-10 xl:py-10 bg-consilium-primary">
                                <div className="text-center mt-4 md:mt-0 flex-grow flex items-center justify-center">
                                    <div className="space-y-5 m-4">
                                        <p className="mx-auto max-w-[700px] text-black font-manrope md:text-xl">
                                            Rather chat on the phone?
                                        </p>
                                        <button 
                                        className="bg-transparent hover:bg-black text-black font-manrope hover:text-white py-2 px-4 border border-black hover:border-black rounded"
                                        onClick={handlePhoneRequestClick}
                                        >
                                            Talk to the team
                                        </button>
                                    </div>
                                </div>
                            </section>
                            </div>
                        )}
                    </div>
                    
                )}

            </main>
            )} 
            <footer className="flex flex-col gap-2 sm:flex-row py-6 w-full shrink-0 items-center px-4 md:px-6 border-t border-black bg-black">
                <p className="text-xs text-white">© Juno. All rights reserved.</p>
                <nav className="sm:ml-auto flex gap-4 sm:gap-6">
                    <Link className="text-xs text-white hover:underline underline-offset-4" to="https://www.termsfeed.com/live/868ca480-60e5-4091-a7dd-3856efc2c37d">
                        Terms of Service
                    </Link>
                    <Link className="text-xs text-white hover:underline underline-offset-4" to="https://www.termsfeed.com/live/4f3ebefe-fb02-4b06-818c-66a22151d721">
                        Privacy
                    </Link>
                </nav>
            </footer>
            
            <CookieConsent
                location="bottom"
                buttonText="Okay"
                style={{ background: "black", color: "white" }}
                buttonStyle={{ background: "white", color: "black", fontSize: "13px" }}
                expires={150}
            >
                This website uses cookies to enhance the user experience.
            </CookieConsent>
        </div>
    )
}

export default ChatPage;
