// require('dotenv').config();

// Asynchronously posts the userIdentifier to an API endpoint
export const postUserIdentifier = async (userIdentifier) => {
    console.log('posting useridentifier', userIdentifier);
    const apiUrl = 'https://api.superdashhq.com/api/v1/webhookstatus/extractConversationDataUsingUserIdentifier/65fc2be19e6e4353ee27d7df/'+userIdentifier; // Replace with your actual API endpoint URL
    try {
      // console.log('superdash_api_key', process.env.REACT_APP_SUPERDASH_API_KEY);
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': process.env.REACT_APP_SUPERDASH_API_KEY

        },
        // body: JSON.stringify({ userIdentifier }),
      });

  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      console.log('User identifier posted successfully');
      return response;

    } catch (error) {
      console.error('Error posting user identifier:', error);
    }
  };

export const postUserIdentifierBeacon = (userIdentifier) => {
    const apiUrl = 'https://80r1uiiwg8.execute-api.eu-west-2.amazonaws.com/ConsiliumDeploymentStage/triggerExtraction'; // Replace with your actual API endpoint URL
    const data = JSON.stringify({ 'userIdentifier': userIdentifier, 'projectId': '65fc2be19e6e4353ee27d7df', 'api_key': '2324b39a-a970-4c3b-b8d6-4e29635d2d1d' });
    
    // Use the Beacon API to send the data
    navigator.sendBeacon(apiUrl, data);
}




  export const sendUserDetails = async (userParams) => {
    const url = "https://cbhq5zbs6h.execute-api.eu-west-2.amazonaws.com/demoStage/ingest-user-details";
  
    const headers = {
      "Content-Type": "application/json",
      // "x-api-key": process.env.REACT_APP_INGEST_USER_DETAILS_KEY // demo user details key
      "x-api-key": "6Rvz9eh8AG1jVWdFrAVcJ8bbT1YJFGF06eNddUJ4"
    };
  
    try {
        const userParamsBody = {'body': JSON.stringify(userParams)};
      const response = await fetch(url, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(userParamsBody),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      console.log('Response Data:', data);
      return data.inserted_id; // Assuming the response JSON contains the inserted_id field directly
    } catch (error) {
      console.error("Error sending user details:", error);
    }
  }

  export const sendPhoneRequest = async (userParams) => {
    const url = "https://cbhq5zbs6h.execute-api.eu-west-2.amazonaws.com/demoStage/ingest-data";
  
    const headers = {
      "Content-Type": "application/json",
      "x-api-key": process.env.REACT_APP_DATA_INGEST_KEY, // consilium user details key
    };
  
    try {
        userParams.conversationId = "phone_request";
        userParams.phoneRequest = true;
        // const userParamsBody = JSON.stringify(userParams);
      const response = await fetch(url, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(userParams),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      console.log('Response Data:', data);
      return data.body; // Assuming the response JSON contains the inserted_id field directly
    } catch (error) {
      console.error("Error sending user details:", error);
    }
  }
  
export const getConversationByUserIdentifier = async (userIdentifier) => {
  const apiUrl = 'https://api.superdashhq.com/api/v1/conversation/project/65fc2be19e6e4353ee27d7df/'+userIdentifier; 
  try {
    const response = await fetch(apiUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': process.env.REACT_APP_SUPERDASH_API_KEY

      },
      // body: JSON.stringify({ userIdentifier }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    // const data = await response.json();
    console.log('Retrieved conversation: ', response);
    return response;
    // let responseObj = response.json()
    // console.log("messages:", responseObj);
  } catch (error) {
    console.error('Error retrieving conversation', error);
    return null
  }
};